import Vue from 'vue'
import App from './App.vue'
import 'vant/lib/swipe/style';
import 'vant/lib/swipe-item/style';
import 'vant/lib/loading/style';
import { Swipe, SwipeItem, Loading } from 'vant';
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Loading);
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
}).$mount('#app')

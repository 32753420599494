<template>
  <div id="app">

    <div class="start" v-if="startpage == true">
      <div class="startload"><van-loading color="#1989fa"/></div>
    </div>

    <div class="prev" @click="changeSwipe('up')"></div>
    <div class="next button" @click="changeSwipe('down')"></div>
    <van-swipe @change="onChange" ref="swiper">
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/1.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/2.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/3.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/4.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/5.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/6.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/7.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/8.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/9.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/10.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/11.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/12.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/13.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/14.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/15.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/16.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/17.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/18.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/19.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/20.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/21.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/22.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/23.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/24.jpg');"></div></div></van-swipe-item>
      <van-swipe-item><div class="pageContent"><div class="pageContentImage" style="background-image: url('https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/end.jpg');"></div></div></van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">我们的第 {{ current + 1 }} 篇</div>
      </template>
    </van-swipe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0,
      startpage: true

    };
  },
  mounted(){
    setTimeout(this.closeLoadpage, 2000)
  },
  methods: {
    closeLoadpage(){
      this.startpage = false
    },
    onChange(index) {
      this.current = index;
    },
    changeSwipe(type) {
      if(type == 'up') {
        console.log(1)
        this.$refs.swiper.prev();
      } else if (type == 'down') {
        console.log(2)
        this.$refs.swiper.next();
      }
      
  }
  },
};
</script>

<style>
  body{
    position: relative;
    margin: 0;
    border: 0;
    width: 100%;
    height: 100vh;
  }
  .custom-indicator {
    position: absolute;
    top:10px;
    right: 0px;
    left: 0px;
    text-align: center;
    margin: auto;
    width: 100px;
    padding: 2px 5px;
    font-size: 16px;
    background: rgba(255, 4, 180, 0.39);
    color: #ffffff;
    border-radius: 8px;
  }
  .start{
    z-index: 999999999999999999999999;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://love-tanxue-1251754029.cos.ap-guangzhou.myqcloud.com/image/page/start.jpg");
    background-position: center bottom;
  }
  .startload{
      position: absolute;
      margin: auto ;
      bottom: 30px;
      left: 0px;
      right: 0px;
      width: 40px;
      height: 40px;
    }
  .pageContentImage{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .prev{
    z-index: 999999999;
    background-image: url('https://love-tanxue.weivird.com/image/prev.png');
    width: 156px;height: 60px;
    position: absolute;
    left: 15px;
    bottom: 15px;
  }
  .next{
    z-index: 999999999;
    background-image: url('https://love-tanxue.weivird.com/image/next.png') !important;
    width: 156px;height: 60px;
    position: absolute;
    right: 15px;
    bottom: 15px;
  }
  .pageContent{
    width: 100%;
    height: 100vh;
  }
</style>